var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ABreadcrumb',{attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{attrs:{"exact":true,"text":"","color":"primary","to":{
        name: 'PageMainEventScoreboard',
        params: { code: _vm.$route.params.eventCode },
        query: { pw: _vm.$route.query.pw },
      }}},[_vm._v(_vm._s(_vm.$t("route.PageMainEventScoreboard")))]),_c('v-btn',{attrs:{"exact":true,"text":"","color":"primary","to":{
        name: 'PageMainEventGuide',
        params: { code: _vm.$route.params.eventCode },
        query: { pw: _vm.$route.query.pw },
      }}},[_vm._v(_vm._s(_vm.$t("route.PageMainEventGuide")))]),(
        _vm.auth &&
        _vm.auth.Account &&
        (_vm.auth.Account.role == 'moderator' || _vm.auth.Account.role == 'admin')
      )?_c('v-btn',{attrs:{"exact":true,"text":"","color":"primary","to":{
        name: 'PageMainEventAnalysis',
        params: { code: _vm.$route.params.eventCode },
        query: { pw: _vm.$route.query.pw },
      }}},[_vm._v(_vm._s(_vm.$t("route.PageMainEventAnalysis")))]):_vm._e()],1),(_vm.api.loading)?_c('div',{staticClass:"text-center pa-5"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):_vm._e(),(_vm.data)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[(_vm.data.event)?[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("model.name.event")))]),_c('td',[_vm._v(_vm._s(_vm.data.event.name))]),_c('td',{staticClass:"text-right"},[_c('JsonExcel',{attrs:{"name":"event.xls","worksheet":"Hamochi","data":_vm.event}},[_c('v-btn',{attrs:{"x-small":"","color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("action.download"))+" ")])],1)],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("model.prop.dateStart")))]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(_vm.data.event.timestampStart).format("LLL"))+" ")]),_c('td')]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("model.prop.dateEnd")))]),_c('td',[_vm._v(_vm._s(_vm.$moment(_vm.data.event.timestampEnd).format("LLL")))]),_c('td')])]:_vm._e(),(_vm.data.school)?[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("model.name.school")))]),_c('td',[_vm._v(_vm._s(_vm.data.school.name))]),_c('td',{staticClass:"text-right"},[_c('JsonExcel',{attrs:{"name":"school.xls","worksheet":"Hamochi","data":_vm.school}},[_c('v-btn',{attrs:{"x-small":"","color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("action.download"))+" ")])],1)],1)])]:_vm._e()],2)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mb-3"},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_vm._l(([
                'checkpoints',
                'classrooms',
                'students',
                'participants',
                'submissions',
                'questions',
                'records',
                'labRecords' ]),function(d,i){return [(_vm.data[d])?_c('tr',{key:i},[_c('td',[_vm._v(_vm._s(_vm.$t(("model.name." + d))))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.data[d].length))]),_c('td',{staticClass:"text-right"},[_c('JsonExcel',{attrs:{"name":d,"worksheet":"Hamochi","data":_vm.data[d]}},[_c('v-btn',{attrs:{"x-small":"","color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("action.download"))+" ")])],1)],1)]):_vm._e()]}),(_vm.activity)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("model.name.activity")))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.activity.length))]),_c('td',{staticClass:"text-right"},[_c('JsonExcel',{attrs:{"name":"activity","worksheet":"Hamochi","data":_vm.activity}},[_c('v-btn',{attrs:{"x-small":"","color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("action.download"))+" ")])],1)],1)]):_vm._e()],2)])],1)],1)],1):_vm._e(),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"0"},[_vm._v("All")]),_c('v-tab',{key:"1"},[_vm._v("By Gender")]),_c('v-tab',{key:"2"},[_vm._v("By Platform")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.data && _vm.data.activity)?_c('TableTrend',{attrs:{"activity":_vm.activity,"data":_vm.data}}):_vm._e()],1),_c('v-tab-item',[_vm._l((['Male', 'Female', 'Unknown']),function(gender,i){return [(
            _vm.dataMutated[("Gender" + gender)] &&
            _vm.dataMutated[("Gender" + gender)].students.length > 0
          )?_c('div',{key:i,staticClass:"mb-3"},[_c('div',{staticClass:"text-h6 mb-1"},[_vm._v(_vm._s(gender))]),_c('TableTrend',{attrs:{"data":_vm.dataMutated[("Gender" + gender)],"showSubmission":false}})],1):_vm._e()]})],2),_c('v-tab-item',[_c('v-card',{staticClass:"ma-1"},[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("Platforms")]),_c('th',[_vm._v("User")]),_c('th',[_vm._v("Visit")])])]),_c('tbody',[_c('tr',[_c('td',{attrs:{"rowspan":"3"}},[_vm._v("Both")]),_c('td',[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.platforms.uid.both))]),_c('td',[_vm._v(_vm._s(_vm.platforms.visit.both.total))])]),_c('tr',[_c('td',[_vm._v("Full")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v(_vm._s(_vm.platforms.visit.both.full))])]),_c('tr',[_c('td',[_vm._v("Lite")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v(_vm._s(_vm.platforms.visit.both.lite))])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Full")]),_c('td',[_vm._v(_vm._s(_vm.platforms.uid.full))]),_c('td',[_vm._v(_vm._s(_vm.platforms.visit.full))])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Lite")]),_c('td',[_vm._v(_vm._s(_vm.platforms.uid.lite))]),_c('td',[_vm._v(_vm._s(_vm.platforms.visit.lite))])])])])],1),_vm._l((['Both', 'Full', 'Lite', 'Unknown']),function(j,i){return [(
            _vm.dataMutated[("Platform" + j)] &&
            _vm.dataMutated[("Platform" + j)].students.length > 0
          )?_c('div',{key:i,staticClass:"mb-3"},[_c('div',{staticClass:"text-h6 mb-1"},[_vm._v(_vm._s(j))]),_c('TableTrend',{attrs:{"data":_vm.dataMutated[("Platform" + j)],"showSubmission":false}})],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }